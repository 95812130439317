<template>
  <div class="external-module-parameters">
    <PageTitle
      title="第三方串接模組設定"
      icon="chevron_left"
      hideBtn
      style="margin-bottom: 64px"
      @iconClick="$router.push({ name: 'Parameters' })"
    />

    <div class="flex flex-col" style="gap: 24px">
      <ExternalModuleSettings />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import PageTitle from '@/components/Title/PageTitle'
import ExternalModuleSettings from './components/ExternalModuleSettings/ExternalModuleSettings.vue'

export default defineComponent({
  name: 'ExternalIntegrateParameters',
  components: { PageTitle, ExternalModuleSettings },
  setup (props) {
    return {}
  },
})
</script>

<style scoped lang="postcss">
.parameters-category-list {
  @apply grid grid-cols-3 gap-[44px];
}

@media screen and (max-width: 1200px) {
  .parameters-category-list {
    @apply grid grid-cols-2;
  }
}
</style>
