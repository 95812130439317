<template>
  <div class="pospal-api-form">
    <el-form-item label="門店 API Host" prop="host">
      <el-input v-model="syncFields.host" :disabled="disableFields" />
    </el-form-item>
    <el-form-item label="門店 appId" prop="appId">
      <el-input v-model="syncFields.appId" :disabled="disableFields" />
    </el-form-item>
    <el-form-item label="門店 appKey" prop="appKey">
      <el-input v-model="syncFields.appKey" :disabled="disableFields" />
    </el-form-item>

    <el-form-item prop="memberPrefix">
      <template slot="label">
        <FormItemTooltipLabel label="會員編號前綴" :tooltipWidth="200">
          提示訊息文字：<br>
          會員編號前綴是作為在 POS 端區分各門店會員使用<br>
          若是您在 POS 端會員為互通，請在各分店間填入相同前綴<br>
          若是您在 POS 端會員不相通，請在各分店間填入不同前綴<br>
        </FormItemTooltipLabel>
      </template>
      <el-input v-model="syncFields.memberPrefix" :disabled="disableFields" />
    </el-form-item>
    <!-- <el-form-item prop="enableDisplayTransaction">
      <template slot="label">
        <FormItemTooltipLabel label="顯示瑞乘消費紀錄" :tooltipWidth="200">
          若開啟則表示客人端可以查看瑞乘消費紀錄
        </FormItemTooltipLabel>
      </template>
      <el-switch
        v-model="syncFields.enableDisplayTransaction"
        inactive-text="關閉"
        active-text="開啟"
      />
    </el-form-item>
    <el-form-item prop="enableDisplayBalance">
      <template slot="label">
        <FormItemTooltipLabel label="顯示瑞乘會員餘額紀錄" :tooltipWidth="200">
          若開啟則表示客人端可以查看瑞乘會員餘額紀錄
        </FormItemTooltipLabel>
      </template>
      <el-switch
        v-model="syncFields.enableDisplayBalance"
        inactive-text="關閉"
        active-text="開啟"
      />
    </el-form-item> -->

    <p>會員同步完成時間：{{ displaySyncTime }}</p>
  </div>
</template>

<script>
import { useVModel } from '@vueuse/core'
import { defineComponent, onMounted, ref, computed } from 'vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { GetPospalClientConfig } from '@/api/pospal'
import { useShop } from '@/use/shop'
import { get } from 'lodash'
import { formatDate } from '@/utils/date'

export default defineComponent({
  name: 'PospalApiForm',
  components: { FormItemTooltipLabel },
  props: {
    formData: { type: Object, default: () => ({}) },
    disableFields: Boolean,
  },
  emits: ['update:formData'],
  setup (props, { emit }) {
    const { shopId } = useShop()
    const syncFields = useVModel(props, 'formData', emit)

    const registerInfo = ref(null)

    const displaySyncTime = computed(() => {
      const done = get(registerInfo.value, 'registerStatus') === 'done'
      if (done) {
        const dateTime = get(registerInfo.value, 'registerSuccessAt')
        return formatDate(dateTime, 'YYYY/MM/DD HH:mm:ss')
      }
      return '-'
    })

    const getPospalClientConfig = async () => {
      const [res, err] = await GetPospalClientConfig({
        shopId: shopId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      registerInfo.value = res
      console.log(res)
    }

    onMounted(async () => {
      getPospalClientConfig()
    })

    return {
      syncFields,
      displaySyncTime,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
