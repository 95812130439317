<template>
  <BaseDialog title="編輯第三方串接模組設定" :btn1Loading="loading" @close="$emit('close')" @confirm="onConfirm">
    <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <el-form-item prop="enable">
        <template slot="label">
          <FormItemTooltipLabel label="啟用第三方串接設定" :tooltipWidth="200">
            第一次開啟後的1:00AM將會開始進行會員同步
          </FormItemTooltipLabel>
        </template>
        <el-switch
          v-model="formData.enable"
          inactive-text="關閉"
          active-text="開啟"
        />
      </el-form-item>

      <el-form-item label="第三方串接廠商" prop="provider">
        <el-select v-model="formData.provider" :disabled="formData.enable">
          <el-option
            v-for="option in displayProviderOptions"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
      </el-form-item>

      <PospalApiForm v-if="formData.provider === 'pospal' " :formData.sync="formData" :disableFields="formData.enable" />

      <!-- TODO 三方廠商串接欄位組件 -->
    </el-form>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import PospalApiForm from '@/components/ThirdParty/Pospal/PospalApiForm.vue'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import { computed, defineComponent, onMounted, set } from 'vue'
import { useShop } from '@/use/shop'
import { get, omit, find, omitBy } from 'lodash'
import { CreatePospalConfig, UpdatePospalConfig } from '@/api/pospal'
// config
import { customModuleOptionsConfig } from '@/config/customModule'
import { pospalApiFieldsConfig, pospalApiFieldsRulesConfig } from '@/config/pospal'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'ExternalModuleSettingsEditModal',
  components: { BaseDialog, FormItemTooltipLabel, PospalApiForm },
  props: {
    configData: { type: Array, default: () => [] },
  },
  emits: ['refresh', 'close'],
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { checkAction } = usePermissions()
    const { initFormData, formData, formRef, onSubmit, loading } = useBaseForm()
    initFormData({
      enable: false,
      provider: null,
      ...pospalApiFieldsConfig,
    })
    const formRules = {
      // enable: [noEmptyRules()],
      provider: [noEmptyRules()],
      ...pospalApiFieldsRulesConfig,
    }

    const displayProviderOptions = computed(() => {
      const omitList = []
      if (!checkAction('admin.shopCustomModule.createPosPal')) omitList.push('pospal')
      return omit(customModuleOptionsConfig, omitList)
    })

    const submitData = computed(() => {
      const omitList = ['provider']
      const data = { ...formData }
      // data.memberPrefix = data.memberPrefix.trim()
      console.log('data.memberPrefix', data.memberPrefix)
      if (data.memberPrefix) data.memberPrefix = data.memberPrefix.trim()
      console.log('data.memberPrefix', data.memberPrefix)
      console.log('data.memberPrefix', data.memberPrefix)
      if (!data.memberPrefix) {
        // omitList.push('memberPrefix')
        data.memberPrefix = ''
      }
      return omit(data, omitList)
    })

    const handleSubmit = async (data) => {
      const config = props.configData
      const pospalConfig = find(config, { type: 'posPal' })
      let apiMethod = UpdatePospalConfig
      if (!pospalConfig) apiMethod = CreatePospalConfig
      const [res, err] = await apiMethod({
        shopId: shopId.value,
        config: { ...data },
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功！')
      emit('refresh')
      emit('close')
    }

    const syncData = () => {
      const config = props.configData
      const pospalConfig = find(config, { type: 'posPal' })
      if (!pospalConfig) return
      formData.enable = get(pospalConfig, 'config.enable')
      formData.provider = 'pospal'
      for (const key in pospalApiFieldsConfig) {
        formData[key] = get(pospalConfig, `config.${key}`)
      }
    }

    const onConfirm = async () => {
      await onSubmit(handleSubmit, submitData.value)
    }

    onMounted(() => {
      syncData()
    })
    return { formData, formRef, formRules, onConfirm, loading, customModuleOptionsConfig, displayProviderOptions }
  },
})
</script>

<style lang="postcss" scoped>

</style>
