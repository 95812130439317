<template>
  <div class="external-module-settings section-block">
    <SectionTitle title="第三方串接模組設定" @edit="modal.edit = true" />
    <el-form label-position="left" label-width="220px">
      <el-form-item v-for="field in displayData" :key="field.label" :label="field.label">
        {{ field.value }}
      </el-form-item>
    </el-form>

    <ExternalModuleSettingsEditModal
      v-if="modal.edit"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, reactive, onMounted } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import ExternalModuleSettingsEditModal from './ExternalModuleSettingsEditModal.vue'
import { get, find } from 'lodash'
import { GetCustomModule } from '@/api/customModule'
import { useShop } from '@/use/shop'
import { customModuleOptionsConfig } from '@/config/customModule'
import { pospalApiFieldsConfig } from '@/config/pospal'

export default defineComponent({
  name: 'ExternalModuleSettings',
  components: { SectionTitle, ExternalModuleSettingsEditModal },
  emits: ['refresh'],
  setup (props) {
    const { shopId } = useShop()
    const loading = ref(false)
    const configData = ref({})
    const modal = reactive({
      edit: false,
    })
    const displayData = computed(() => {
      const config = configData.value
      const pospalConfig = find(config, { type: 'posPal' })
      if (!pospalConfig) {
        return [
          { label: '啟用第三方串接模組', value: '關閉' },
          { label: '第三方串接廠商', value: '尚未設定' },
          // { label: '顯示瑞乘消費紀錄', value: '尚未設定' },
          // { label: '顯示瑞乘會員餘額紀錄', value: '尚未設定' },
        ]
      }
      const enableDisplayBalance = get(pospalConfig, 'config.enableDisplayBalance')
      const enableDisplayTransaction = get(pospalConfig, 'config.enableDisplayTransaction')
      return [
        { label: '啟用第三方串接模組', value: get(pospalConfig, 'config.enable') ? '啟用' : '關閉' },
        { label: '第三方串接廠商', value: get(customModuleOptionsConfig, 'pospal.label') },
        // { label: '顯示瑞乘消費紀錄', value: enableDisplayTransaction ? '啟用' : '關閉' },
        // { label: '顯示瑞乘會員餘額紀錄', value: enableDisplayBalance ? '啟用' : '關閉' },
      ]
    })

    const getCustomModule = async () => {
      const [res, err] = await GetCustomModule({
        shopId: shopId.value,
      })
      if (err) throw err
      console.log(res)
      configData.value = res
    }

    const refresh = async () => {
      console.log('refresh')
      try {
        loading.value = true
        await getCustomModule()
        loading.value = false
      } catch (error) {
        window.$message.error(error)
      }
    }

    onMounted(async () => {
      refresh()
    })

    return {
      modal,
      loading,
      displayData,
      configData,
      refresh,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
