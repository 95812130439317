import { admin2 } from '@/api/instance'

export const GetCustomModule = async ({
  shopId,
  start,
  limit,
  type,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/shopCustomModule`,
    params: {
      start,
      limit,
      type,
    },
  })
}

export const GetCustomModuleCount = async ({
  shopId,
  type,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/shopCustomModule/count`,
    params: {
      type,
    },
  })
}

export const FindCustomModule = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/shopCustomModule/${id}`,
  })
}
